import { useRef, useEffect } from 'react';
import styles from './index.module.css';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import useIsMobile from '@/hooks/use-is-mobile';
import Head from 'next/head';
const generateVideoSchema = ({
  title,
  description,
  video
}: {
  title: string;
  description: string;
  video: string;
}) => ({
  '@context': 'https://schema.org',
  '@type': 'VideoObject',
  name: title,
  description: description,
  thumbnailUrl: video,
  uploadDate: new Date().toISOString(),
  // Use the current date for the example
  contentUrl: video,
  embedUrl: video
});
const CardProject: React.FC<{
  src: string;
  title: string;
  description: string;
  video: string;
  url: string;
}> = ({
  src,
  title,
  description,
  video,
  url
}) => {
  const {
    t
  } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const router = useRouter();
  const handleMouseEnter = () => {
    if (!isMobile) {
      videoRef?.current?.play();
    }
  };
  const schema = generateVideoSchema({
    title: t(title),
    description: t(description),
    video
  });
  const isMobile = useIsMobile();
  const handleMouseLeave = () => {
    if (videoRef.current && !isMobile) {
      videoRef.current.pause();
      // Optionally reset the video to start
      // videoRef.current.currentTime = 0;
    }
  };
  useEffect(() => {
    if (isMobile) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }, [isMobile]);
  const handleOpenCategory = () => {
    router.push(url);
  };
  return <div className={styles.card} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleOpenCategory} data-sentry-component="CardProject" data-sentry-source-file="index.tsx">
            <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
                <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(schema)
      }} />
            </Head>
            <div className={styles.img}>
                <video ref={videoRef} width="800" height="450" controls={false} controlsList="nofullscreen nodownload" disablePictureInPicture preload="metadata" autoPlay loop playsInline muted // Adding muted for better user experience
      >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className={styles.title} suppressHydrationWarning>
                {t(title)}
            </div>
        </div>;
};
export default CardProject;