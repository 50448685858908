import HeadingLine from '@/components/heading-line';
import { FC, ReactNode } from 'react';

/**
 * Default wrapper that is used for sections (at the moment mainly on the public pages)
 * It provedes the heading and the padding and etc. The rest is handled by the children passed on it.
 */
const SectionWrapper: FC<{
  children: ReactNode;
  text: string;
  id: string;
}> = ({
  children,
  text,
  id,
  ...props
}) => {
  return <section id={id} className="py-10 my-10" {...props} data-sentry-component="SectionWrapper" data-sentry-source-file="section.tsx">
            <div className="w-full max-w-screen-2xl ml-auto mr-auto px-6 2xl:px-6">
                <div className="mb-28">
                    <HeadingLine text={text} data-sentry-element="HeadingLine" data-sentry-source-file="section.tsx" />
                </div>
                {children}
            </div>
        </section>;
};
export default SectionWrapper;