import Stage from './components/stage';
import Services from './components/services';
import WhyChooseUs from './components/why-choose-us';
import HowItWorks from '@/components/how-it-works';
import Reviews from '@/components/reviews';
import OurSolutions from './components/solutions';
import BecomeSupplier from './components/become-supplier';
import PopularProjects from '@/components/popular-projects';
const Homepage: React.FC = () => {
  return <>
            <Stage data-sentry-element="Stage" data-sentry-source-file="index.tsx" />
            <Services data-sentry-element="Services" data-sentry-source-file="index.tsx" />
            <PopularProjects data-sentry-element="PopularProjects" data-sentry-source-file="index.tsx" />
            <OurSolutions data-sentry-element="OurSolutions" data-sentry-source-file="index.tsx" />
            <BecomeSupplier data-sentry-element="BecomeSupplier" data-sentry-source-file="index.tsx" />
            <WhyChooseUs data-sentry-element="WhyChooseUs" data-sentry-source-file="index.tsx" />
            <HowItWorks data-sentry-element="HowItWorks" data-sentry-source-file="index.tsx" />
        </>;
};
export default Homepage;