import styles from './index.module.css';
import { useTranslation } from 'next-i18next';
const HeadingLine: React.FC<{
  text: string;
}> = ({
  text
}) => {
  const {
    t
  } = useTranslation('common');
  return <div className={styles.heading} data-sentry-component="HeadingLine" data-sentry-source-file="index.tsx">
            <div className={styles.line}></div>
            <div className={styles.text}>{t(text)}</div>
        </div>;
};
export default HeadingLine;