import { useState } from 'react';
import ServiceSupplierSearchCarousel from './service-supplier-search-carousel';
import { useGetPublicProfile } from '@/features/supplier/api/use-get-public-profile';
import ProfileCard from '@/features/supplier/components/profile-card';
import LoaderContainer from '@/containers/loader';
const ServicePopularSuppliers = () => {
  const [selectedServiceId, setSelectedServiceId] = useState<number | null>(null);
  const profiles = useGetPublicProfile(1, 5, selectedServiceId ? selectedServiceId.toString() : '');
  const suppliers = profiles?.data?.data?.suppliers || [];
  const handleSelect = (serviceId: number | null) => {
    setSelectedServiceId(serviceId);
    profiles.refetch();
  };
  return <div data-sentry-component="ServicePopularSuppliers" data-sentry-source-file="service-popular-suppliers.tsx">
            <ServiceSupplierSearchCarousel selectedService={selectedServiceId} handleServiceSelect={handleSelect} data-sentry-element="ServiceSupplierSearchCarousel" data-sentry-source-file="service-popular-suppliers.tsx" />
            {selectedServiceId ? <LoaderContainer isLoading={profiles.isPending}>
                    <div className="mt-10 w-full max-w-[1260px] ml-auto mr-auto">
                        {suppliers.map(supplier => <ProfileCard key={supplier.uuid} supplier={supplier} />)}
                    </div>
                </LoaderContainer> : ''}
        </div>;
};
export default ServicePopularSuppliers;