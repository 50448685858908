import { FC } from 'react';
import CardProject from '@/components/card-project';
import SectionWrapper from '@/containers/section';
import { useGetServices } from '@/features/services/api/use-get-services';
import { ProjectItem, popularProjects } from '@/utils/constants/projects';
const PopularProjects: FC = () => {
  const servicesResponse = useGetServices();
  const services = servicesResponse?.data?.data?.categories || [];
  const generateProjectNavigationUrl = (project: ProjectItem) => {
    let category = services?.find(item => item.category_name_en === project.category);
    let service = null;
    let url = '';
    if (!category) {
      category = services?.find(item => item.category_name_en === project.fallback);
    }
    if (!!category) {
      url = `/categories?categoryId=${category?.category_id?.toString()}`;
      service = category?.services.find(item => item.name_en === project.service);
    }
    if (!!service) {
      url += `&serviceId=${service?.id}`;
    }
    return url ? url + '&page=1' : '#';
  };
  return <SectionWrapper id="projects" text="HOMEPAGE.POPULAR_PROJECTS" data-sentry-element="SectionWrapper" data-sentry-component="PopularProjects" data-sentry-source-file="index.tsx">
            <div className="w-full max-w-screen-xl ml-auto mr-auto flex flex-wrap gap-10 items-center">
                {popularProjects.map((project, index) => <CardProject key={index} src={project?.image} title={project?.title} description={project?.description} video={project?.video} url={generateProjectNavigationUrl(project)} />)}
            </div>
        </SectionWrapper>;
};
export default PopularProjects;