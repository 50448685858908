import cleaning from '@public/images/homepage/cleaning.webp';
import electric from '@public/images/homepage/electric.webp';
import furniture from '@public/images/homepage/furniture.webp';
import lifting from '@public/images/homepage/lifting.webp';
import mount from '@public/images/homepage/mount.webp';
import plumbing from '@public/images/homepage/plumbing.webp';

interface ProjectItem {
    title: string;
    description: string;
    image: string;
    video: string;
    service: string;
    category: string;
    fallback: string;
}

const popularProjects: Array<ProjectItem> = [
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HAIR.TITLE',
        description: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HAIR.DESCRIPTION',
        image: '',
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/hair_logo.mp4',
        service: 'Hairdressing services',
        category: 'Beauty and health services',
        fallback: 'Beauty and health services',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MECHANICS.TITLE',
        description: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MECHANICS.DESCRIPTION',
        image: '',
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/mechanic_logo.mp4',
        service: 'Auto repair',
        category: 'Auto repair',
        fallback: 'Auto repair',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.NAILS.TITLE',
        description: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.NAILS.DESCRIPTION',
        image: '',
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/nails_logo.mp4',
        service: 'Nail care',
        category: 'Beauty and health services',
        fallback: 'Beauty and health services',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.FURNITURE_ASSEMBLY.TITLE',
        description:
            'HOMEPAGE.POPULAR_PROJECTS_BLOCK.FURNITURE_ASSEMBLY.DESCRIPTION',
        image: furniture.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/furniture_assembly.mp4',
        service: 'Furniture works',
        category: 'Furniture works',
        fallback: 'Home Master',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MOUNT_TV.TITLE',
        description: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MOUNT_TV.DESCRIPTION',
        image: mount.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/mount_a_tv.mp4',
        service: 'Home Master',
        category: 'Home Master',
        fallback: 'Home Master',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HEAVY_LIFTING.TITLE',
        description:
            'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HEAVY_LIFTING.DESCRIPTION',
        image: lifting.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/heavy_lifting.mp4',
        service: 'Home Master',
        category: 'Home Master',
        fallback: 'Home Master',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HOME_APARTMENT_CLEANING.TITLE',
        description:
            'HOMEPAGE.POPULAR_PROJECTS_BLOCK.HOME_APARTMENT_CLEANING.DESCRIPTION',
        image: cleaning.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/home_and_app_cleaning.mp4',
        service: 'Cleaning service',
        category: 'Cleaning service',
        fallback: 'Cleaning service',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MINOR_PLUMBING_REPAIRS.TITLE',
        description:
            'HOMEPAGE.POPULAR_PROJECTS_BLOCK.MINOR_PLUMBING_REPAIRS.DESCRIPTION',
        image: plumbing.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/minor_plumbing_repare.mp4',
        service: 'Home Master',
        category: 'Home Master',
        fallback: 'Home Master',
    },
    {
        title: 'HOMEPAGE.POPULAR_PROJECTS_BLOCK.ELECTRICAL_HELP.TITLE',
        description:
            'HOMEPAGE.POPULAR_PROJECTS_BLOCK.ELECTRICAL_HELP.DESCRIPTION',
        image: electric.src,
        video: 'https://fe-static-dev-ofertirai-me.s3.eu-central-1.amazonaws.com/site-videos/mainpage/electrical_help.mp4',
        service: 'Home Master',
        category: 'Home Master',
        fallback: 'Home Master',
    },
];

export { popularProjects };
export type { ProjectItem };
