import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { sequenceDivs } from '@/utils/animations';
import { FC, useEffect, useState } from 'react';
import stageEn from '@public/images/homepage/stage.webp';
import stageBg from '@public/bg/images/homepage/stage.webp';
import useIsMobile from '@/hooks/use-is-mobile';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import useAuthSession from '@/hooks/use-auth-session';
import { useRouter } from 'next/router';
const Stage: FC = () => {
  const isMobile = useIsMobile();
  const {
    t,
    i18n
  } = useTranslation();
  const {
    status
  } = useAuthSession();
  const {
    push
  } = useRouter();
  const handleSupplierButtonClick = () => {
    if (status) {
      push('/supplier/dashboard');
    } else {
      push('/register');
    }
  };
  const handleCustomerButtonClick = () => {
    if (status) {
      push('/customer/dashboard');
    } else {
      push('/register');
    }
  };
  const lang = i18n.language;
  const bannerPath = lang === 'en' ? stageEn : stageBg;
  return <section id="staging" className="relative" suppressHydrationWarning data-sentry-component="Stage" data-sentry-source-file="stage.tsx">
            {isMobile ? <>
                    <motion.div className="flex justify-end" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }}>
                        <Image src={bannerPath} alt="Find everything you need in ofertirai.me" width={958} height={700} className="w-full" loading="lazy" />
                    </motion.div>
                    <motion.div className="w-full max-w-screen-2xl px-6 mt-10 text-center" variants={sequenceDivs.container} initial="hidden" animate="visible">
                        <h1 className="text-text text-4xl md:text-6xl font-bold mb-4 md:mb-10" suppressHydrationWarning>
                            {t('HOMEPAGE.STAGE_TITLE')}
                        </h1>
                        <h2 className="text-text font-light text-2xl md:text-4xl" suppressHydrationWarning>
                            {t('HOMEPAGE.STAGE_SUBTITLE')}
                        </h2>
                        <div className="flex gap-4 mt-10 justify-center items-center flex-col sm:flex-row">
                            <Button variant="secondary" size="md" suppressHydrationWarning type="button" onClick={handleSupplierButtonClick}>
                                {t('HOMEPAGE.STAGE_BUTTON_ONE')}
                            </Button>
                            <Button variant="default" size="md" suppressHydrationWarning type="button" onClick={handleCustomerButtonClick}>
                                {t('HOMEPAGE.STAGE_BUTTON_TWO')}
                            </Button>
                        </div>
                    </motion.div>
                </> : <>
                    <div className="absolute flex justify-around w-full flex items-center h-full">
                        <motion.div className="w-full max-w-screen-2xl px-6" variants={sequenceDivs.container} initial="hidden" animate="visible">
                            <motion.h1 className="text-text text-4xl xl:text-6xl font-bold mb-4 xl:mb-10 w-[55%]" variants={sequenceDivs.item} suppressHydrationWarning>
                                {t('HOMEPAGE.STAGE_TITLE')}
                            </motion.h1>
                            <motion.h2 className="text-text font-light text-2xl xl:text-4xl w-[50%]" variants={sequenceDivs.item} suppressHydrationWarning>
                                {t('HOMEPAGE.STAGE_SUBTITLE')}
                            </motion.h2>
                            <motion.div className="flex gap-4 mt-10" variants={sequenceDivs.item}>
                                <Button variant="secondary" size="md" suppressHydrationWarning onClick={handleSupplierButtonClick}>
                                    {t('HOMEPAGE.STAGE_BUTTON_ONE')}
                                </Button>
                                <Button variant="default" size="md" suppressHydrationWarning onClick={handleCustomerButtonClick}>
                                    {t('HOMEPAGE.STAGE_BUTTON_TWO')}
                                </Button>
                            </motion.div>
                        </motion.div>
                    </div>
                    <motion.div className="flex justify-end" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }}>
                        <Image src={bannerPath} alt="Find everything you need in ofertirai.me" width={958} height={700} className="w-6/12" loading="lazy" />
                    </motion.div>
                </>}
        </section>;
};
export default Stage;