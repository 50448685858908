import SectionWrapper from '@/containers/section';
import whyUsImage from '@public/images/homepage/why-us.webp';
import { FC } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
const WhyChooseUs: FC = () => {
  const {
    t
  } = useTranslation();
  return <SectionWrapper id="why-us" text="HOMEPAGE.WHY_CHOOSE_US.TITLE" data-sentry-element="SectionWrapper" data-sentry-component="WhyChooseUs" data-sentry-source-file="why-choose-us.tsx">
            <div className="flex flex-col-reverse lg:flex-row items-center w-full max-w-screen-xl ml-auto mr-auto">
                <div className="flex-1">
                    <ol>
                        <li key={0} className="mb-4 lg:max-w-[75%]">
                            <span className="text-xl text-text md:text-2xl md:ml-[-20px]" suppressHydrationWarning>
                                1. {t('HOMEPAGE.WHY_CHOOSE_US.STEP_1.TITLE')}
                            </span>
                            <br />
                            <span className="text-text text-lg font-light" suppressHydrationWarning>
                                {t('HOMEPAGE.WHY_CHOOSE_US.STEP_1.DESCRIPTION')}
                            </span>
                        </li>
                        <li key={1} className="mb-4 lg:max-w-[75%]">
                            <span className="text-text text-xl md:text-2xl md:ml-[-20px]" suppressHydrationWarning>
                                2. {t('HOMEPAGE.WHY_CHOOSE_US.STEP_2.TITLE')}
                            </span>
                            <br />
                            <span className="text-text text-lg font-light" suppressHydrationWarning>
                                {t('HOMEPAGE.WHY_CHOOSE_US.STEP_2.DESCRIPTION')}
                            </span>
                        </li>
                        <li key={2} className="mb-4 lg:max-w-[75%]">
                            <span className="text-text text-xl md:text-2xl md:ml-[-20px]" suppressHydrationWarning>
                                3. {t('HOMEPAGE.WHY_CHOOSE_US.STEP_3.TITLE')}
                            </span>
                            <br />
                            <span className="text-text text-lg font-light" suppressHydrationWarning>
                                {t('HOMEPAGE.WHY_CHOOSE_US.STEP_3.DESCRIPTION')}
                            </span>
                        </li>
                    </ol>
                </div>
                <div className="flex-1 w-[75vw] md:w-full h-full">
                    <Image src={whyUsImage.src} alt="Why to choose us?" width={640} height={452} data-sentry-element="Image" data-sentry-source-file="why-choose-us.tsx" />
                </div>
            </div>
        </SectionWrapper>;
};
export default WhyChooseUs;