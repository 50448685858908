/**
 * Animation for displaying divs one after another
 *
 * @note used for stage on homepage and some forms
 * wrap the container with the sequenceDivs.container
 *
 * and then apply the sequenceDivs.item to the items that you want to display
 * one after another
 */
export const sequenceDivs = {
    container: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            },
        },
    },
    item: {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
            },
        },
    },
};
