import supplier from '../../../../public/images/homepage/supplier.webp';
import { FC } from 'react';
import circleCheck from '@public/images/homepage/circle-check.svg';
import { Button } from '@/components/ui/button';
import SectionWrapper from '@/containers/section';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import useAuthSession from '@/hooks/use-auth-session';
const BecomeSupplier: FC = () => {
  const {
    t,
    i18n
  } = useTranslation();
  const {
    push
  } = useRouter();
  const {
    status
  } = useAuthSession();
  const handleButtonClick = () => {
    const lang = i18n.language;
    if (lang === 'bg') {
      push('/bg/register');
    } else {
      push('/register');
    }
  };
  return <SectionWrapper id="become-supplier" text="HOMEPAGE.BECOME_SUPPLIER_BLOCK.BUTTON" data-sentry-element="SectionWrapper" data-sentry-component="BecomeSupplier" data-sentry-source-file="become-supplier.tsx">
            <div className="flex flex-col lg:flex-row items-center gap-10 lg:gap-20 w-full ml-auto mr-auto">
                <div className="flex-1 w-full h-full max-h-[425px]">
                    <Image src={supplier.src} alt="Become supplier" width={622} height={425} data-sentry-element="Image" data-sentry-source-file="become-supplier.tsx" />
                    <div className="w-full h-[40px] bg-amber-400 mt-[-20px]"></div>
                </div>
                <div className="w-full flex-1 mt-10 lg:mt-0">
                    <ul>
                        <li key={0} className="mb-4 md:mb-8 flex gap-2 items-center">
                            <img src={circleCheck?.src} alt="circle check" />
                            <span className="text-text text-base md:text-2xl" suppressHydrationWarning>
                                {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.STEP_1')}
                            </span>
                        </li>
                        <li key={1} className="mb-4 md:mb-8 flex gap-2 items-center">
                            <img src={circleCheck?.src} alt="circle check" />
                            <span className="text-text text-base md:text-2xl" suppressHydrationWarning>
                                {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.STEP_2')}
                            </span>
                        </li>
                        <li key={2} className="mb-4 md:mb-8 flex gap-2 items-center">
                            <img src={circleCheck?.src} alt="circle check" />
                            <span className="text-text text-base md:text-2xl" suppressHydrationWarning>
                                {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.STEP_3')}
                            </span>
                        </li>
                        <li key={3} className="mb-4 md:mb-8 flex gap-2 items-center">
                            <img src={circleCheck?.src} alt="circle check" />
                            <span className="text-text text-base md:text-2xl" suppressHydrationWarning>
                                {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.STEP_4')}
                            </span>
                        </li>
                        <li key={4} className="mb-4 md:mb-8 flex gap-2 items-center">
                            <img src={circleCheck?.src} alt="circle check" />
                            <span className="text-text text-base md:text-2xl" suppressHydrationWarning>
                                {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.STEP_5')}
                            </span>
                        </li>
                    </ul>
                    {!status ? <Button variant="secondary" size="md" suppressHydrationWarning onClick={handleButtonClick}>
                            {t('HOMEPAGE.BECOME_SUPPLIER_BLOCK.BUTTON')}
                        </Button> : ''}
                </div>
            </div>
        </SectionWrapper>;
};
export default BecomeSupplier;