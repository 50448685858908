import ourSolutions from '../../../../public/images/homepage/our-solutions.webp';
import { FC } from 'react';
import { Button } from '@/components/ui/button';
import SectionWrapper from '@/containers/section';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
const OurSolutions: FC = () => {
  const {
    t,
    i18n
  } = useTranslation();
  const {
    push
  } = useRouter();
  const handleButtonClick = () => {
    const lang = i18n.language;
    if (lang === 'bg') {
      push('/bg/categories');
    } else {
      push('/categories');
    }
  };
  return <SectionWrapper id="our-solutions" text="HOMEPAGE.SOLUTIONS.SECTION_TITLE" data-sentry-element="SectionWrapper" data-sentry-component="OurSolutions" data-sentry-source-file="solutions.tsx">
            <div className="flex flex-col-reverse lg:flex-row items-center w-full max-w-screen-xl ml-auto mr-auto">
                <div className="flex-1 mt-10 lg:mt-0">
                    <h3 className="text-2xl text-center md:text-left md:text-3xl font-light text-text mb-4 md:mb-8" suppressHydrationWarning>
                        {t('HOMEPAGE.SOLUTIONS.TITLE')}
                    </h3>
                    <p className="text-text text-center md:text-left font-light text-xl mb-10" suppressHydrationWarning>
                        {t('HOMEPAGE.SOLUTIONS.DESCRIPTION')}
                        <br />
                        {t('HOMEPAGE.SOLUTIONS.DESCRIPTION_TWO')}
                    </p>
                    <Button variant="default" size="md" suppressHydrationWarning onClick={handleButtonClick} data-sentry-element="Button" data-sentry-source-file="solutions.tsx">
                        {t('HOMEPAGE.SOLUTIONS.BUTTON')}
                    </Button>
                </div>
                <div className="flex-1 w-full h-full">
                    <Image src={ourSolutions.src} alt="Your Worries, Our Solutions" className="ml-auto mr-auto md:mr-none" width={380} height={320} data-sentry-element="Image" data-sentry-source-file="solutions.tsx" />
                </div>
            </div>
        </SectionWrapper>;
};
export default OurSolutions;