import step1 from '@public/images/homepage/step-1.webp';
import step2 from '@public/images/homepage/step-2.webp';
import step3 from '@public/images/homepage/step-3.webp';
import step4 from '@public/images/homepage/step-4.webp';
import { FC } from 'react';
import SectionWrapper from '@/containers/section';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
const HowItWorks: FC = () => {
  const {
    t
  } = useTranslation();
  return <SectionWrapper id="steps" text="HOMEPAGE.HOW_IT_WORKS.TITLE" data-sentry-element="SectionWrapper" data-sentry-component="HowItWorks" data-sentry-source-file="index.tsx">
            <div className="flex px-10 sm:px-0 flex-row justify-between flex-wrap items-center w-full max-w-screen-xl ml-auto mr-auto gap-8 items-start">
                <div className="w-full sm:max-w-[224px] flex items-center flex-col justify-between gap-4 border-b-4 border-transparent hover:border-amber-400 transition-all">
                    <Image src={step1.src} alt="How it works - step 1" width={146} height={146} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <h4 className="text-base md:text-lg text-text text-center mb-4" suppressHydrationWarning>
                        {t('HOMEPAGE.HOW_IT_WORKS.CREATE_AN_ORDER')}
                    </h4>
                </div>
                <div className="w-full sm:max-w-[224px] flex items-center flex-col justify-between gap-4 border-b-4 border-transparent hover:border-amber-400 transition-all">
                    <Image src={step2.src} alt="How it works - step 2" width={146} height={146} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <h4 className="text-base md:text-lg text-text text-center mb-4" suppressHydrationWarning>
                        {t('HOMEPAGE.HOW_IT_WORKS.CHOOSE_A_SPECIALIST')}
                    </h4>
                </div>
                <div className="w-full sm:max-w-[224px] flex items-center flex-col justify-between gap-4 border-b-4 border-transparent hover:border-amber-400 transition-all">
                    <Image src={step3.src} alt="How it works - step 3" width={146} height={146} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <h4 className="text-base md:text-lg text-text text-center mb-4" suppressHydrationWarning>
                        {t('HOMEPAGE.HOW_IT_WORKS.SIGNING_THE_CONTRACT')}
                    </h4>
                </div>
                <div className="w-full sm:max-w-[224px] flex items-center flex-col justify-between gap-4 border-b-4 border-transparent hover:border-amber-400 transition-all">
                    <Image src={step4.src} alt="How it works - step 4" width={146} height={146} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <h4 className="text-base md:text-lg text-text text-center mb-4" suppressHydrationWarning>
                        {t('HOMEPAGE.HOW_IT_WORKS.CHECK_YOUR_ORDER')}
                    </h4>
                </div>
            </div>
        </SectionWrapper>;
};
export default HowItWorks;